/* Custom layout */
.color-picker .react-colorful {
  padding: 16px;
  border-radius: 12px;
  background: #ffffffcf;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.32);
}

.color-picker .react-colorful__saturation {
  margin: 8px 0 15px 0;
  border-radius: 6px;
  border-bottom: none;
}

.color-picker .react-colorful__hue,
.color-picker .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.color-picker .react-colorful__hue-pointer,
.color-picker .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.color-picker > input {
  text-align: center;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
  border: 1px solid var(--input-border-color);
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  bottom: 24px;
  width: 168px;
}
